import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

interface UserMenuChevronProps {
  isPartnersNav: boolean
  isMember: boolean
}

const UserMenuChevron = ({ isPartnersNav, isMember }: UserMenuChevronProps) => (
  <i
    className={cx(
      "ml-2",
      isMember || isPartnersNav ? "text-white" : "text-west-side"
    )}
  >
    <FontAwesomeIcon icon={faChevronDown} size="xs" />
  </i>
)

export default UserMenuChevron
