import { ReactNode, useMemo } from "react"
import { useQueryClient } from "@tanstack/react-query"
import cx from "classnames"

import MainLayout from "./Main"
import GlobalNotification, {
  NotificationType,
} from "../../components/GlobalNotification"
import { useGlobalNotifications } from "../../hooks"

export interface DashboardLayoutTypes {
  showGlobalNotifications?: boolean
  children: ReactNode
  subNavigation?: ReactNode
  isLoading?: boolean[] | boolean
  isAccountsPage?: boolean
}

const DashboardLayout = ({
  showGlobalNotifications = true,
  children,
  subNavigation,
  isLoading = [],
  isAccountsPage,
}: DashboardLayoutTypes) => {
  const queryClient = useQueryClient()

  const {
    data: globalNotifications = [],
    isLoading: globalNotificationsIsLoading,
  } = useGlobalNotifications<NotificationType[], Error>(queryClient, {
    enabled: showGlobalNotifications,
  })

  const dataLoaded = !!globalNotifications

  const notificationEls = useMemo(() => {
    if (dataLoaded && globalNotifications) {
      return globalNotifications.map((notification) => (
        <GlobalNotification key={notification.id} notification={notification} />
      ))
    }

    return []
  }, [dataLoaded, globalNotifications])

  return (
    <MainLayout
      className="dashboard"
      contentBg="grey"
      isLoading={[globalNotificationsIsLoading].concat(isLoading)}
    >
      {dataLoaded && (
        <>
          {subNavigation}

          {notificationEls.length ? (
            <div className="container max-w-6xl">
              <div className="inner-container mx-auto mt-8 -mb-4 space-y-4">
                {notificationEls}
              </div>
            </div>
          ) : null}

          <div
            className={`container ${
              isAccountsPage ? "container max-w-1120" : "max-w-6xl"
            }`}
          >
            <div
              className={cx(
                "inner-container mx-auto pb-6 md:pb-12",
                !notificationEls.length && "pt-8"
              )}
            >
              {children}
            </div>
          </div>
        </>
      )}
    </MainLayout>
  )
}

export default DashboardLayout
