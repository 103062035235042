import cx from "classnames"

interface UserMenuNameProps {
  name: string
  isMember: boolean
  isPartnersNav: boolean
}

const UserMenuName = ({ name, isMember, isPartnersNav }: UserMenuNameProps) => (
  <span
    className={cx(
      "flex-1 truncate font-semibold",
      isMember || isPartnersNav ? "text-white" : "text-west-side"
    )}
  >
    {name}
  </span>
)

export default UserMenuName
