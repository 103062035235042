import { useState, useEffect } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { Navigate, useLocation } from "react-router"

import MainLayout from "../_layouts/Main"
import {
  useAccountId,
  useAccountInterest,
  useAccountUrlPrefix,
} from "../../hooks"
import OnboardingSteps from "../../sections/Onboarding/OnboardingSteps"
import { AccountInterestTypes } from "@/types/accountInterest"

const OnboardingSurvey = () => {
  const accountId = useAccountId()
  const [onboardingStep, setOnboardingStep] = useState<number>(0)
  const [surveyCompleted, setSurveyCompleted] = useState<boolean>(false)

  const location = useLocation()
  const accountUrlPrefix = useAccountUrlPrefix()
  const queryClient = useQueryClient()

  const { data: accountInterest, isLoading: accountInterestIsLoading } =
    useAccountInterest<AccountInterestTypes, Error>(queryClient, accountId)

  const fromDashboard = location?.state?.fromDashboard

  useEffect(() => {
    setOnboardingStep(0)
  }, [accountId])

  useEffect(() => {
    // send user to loading screen if they added parcels after survey
    if (location?.state?.refreshPropertyBoundaries) {
      setSurveyCompleted(true)
    }
  }, [location?.pathname, location?.state?.refreshPropertyBoundaries])

  const progressStep = surveyCompleted ? 9 : onboardingStep + 1

  const maxSteps = fromDashboard ? 7 : 10

  if (surveyCompleted && accountInterest?.has_answered_all_questions) {
    return <Navigate replace to={`${accountUrlPrefix}/onboarding/overview`} />
  }

  return (
    <MainLayout
      isLoading={[accountInterestIsLoading]}
      contentBg="white"
      className="onboarding"
    >
      <OnboardingSteps
        onboardingStep={onboardingStep}
        setOnboardingStep={setOnboardingStep}
        setSurveyCompleted={setSurveyCompleted}
        progressStep={progressStep}
        maxSteps={maxSteps}
        accountInterest={accountInterest}
        fromDashboard={fromDashboard}
      />
    </MainLayout>
  )
}

export default OnboardingSurvey
