import { useState } from "react"
import { Link, useLocation } from "react-router"
import { useQueryClient } from "@tanstack/react-query"
import { matchSorter } from "match-sorter"

import DashboardLayout from "./_layouts/DashboardLayout"
import AccountRows from "../sections/Accounts/AccountRows"
import SearchField from "../components/SearchField"
import { NoConnectedAccountsCard } from "../components/NoConnectedAccountsCard"
import { Notification } from "../components/Notification"
import { useDashboardAccounts } from "../hooks"

const Accounts = () => {
  const queryClient = useQueryClient()
  const [searchTerm, setSearchTerm] = useState("")
  const location = useLocation()
  const wasRedirected = location.state?.from?.pathname.includes("programs")

  const { data, isLoading: accountsIsLoading } =
    useDashboardAccounts(queryClient)

  const accounts = data && data.object_list

  const filteredAccounts = matchSorter(accounts || [], searchTerm, {
    keys: ["account_name"],
  })

  return (
    <DashboardLayout isLoading={accountsIsLoading} isAccountsPage={true}>
      {accounts && (
        <>
          {accounts.length === 0 ? (
            <NoConnectedAccountsCard />
          ) : (
            <div className="flex">
              <div className="w-full">
                {wasRedirected && (
                  <Notification
                    title="Select an account to view programs"
                    variant="error"
                    border="full"
                    className="mb-4"
                  >
                    <p>
                      Sorry, you must pick an account before you can view
                      programs.
                    </p>
                  </Notification>
                )}
                <div className="flex flex-wrap items-center">
                  <h1 className="text-2xl">Accounts</h1>

                  <span className="ml-auto order-1 w-full mt-4 sm:order-none sm:w-auto sm:mt-0">
                    <SearchField
                      id="search-accounts"
                      onChange={setSearchTerm}
                      placeholder="Search accounts"
                    />
                  </span>

                  <Link
                    to="/accounts/new"
                    className="btn2 btn2-primary font-semibold ml-auto  sm:ml-4"
                  >
                    Add Account
                  </Link>
                </div>

                <div className="mt-6">
                  <AccountRows
                    filteredAccounts={filteredAccounts}
                    searchTerm={searchTerm}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </DashboardLayout>
  )
}

export default Accounts
