import { useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { Formik } from "formik"
import * as Yup from "yup"

import MainLayout from "./_layouts/Main"
import SylviaContent from "../sections/Sylvia/SylviaContent"
import SylviaFormComponent from "../sections/Sylvia/SylviaFormComponent"
import { usePostSylviaQuery } from "../hooks"
import { SylviaResponseTypes } from "@/types/sylvia"
import { AxiosError } from "axios"

interface SylviaFormValues {
  query: string
  corpus_name: string
  model_name: string
  top_k: number | ""
  vector_distance_threshold: number | ""
  vector_similarity_threshold: number | ""
  metadata_filter: string
  system_instruction: string
}

const validationSchema = Yup.object().shape({
  query: Yup.string().required(),
})

const Sylvia = () => {
  const queryClient = useQueryClient()
  const [showOptional, setShowOptional] = useState<boolean>(false)

  const {
    data,
    mutateAsync: postSylviaQuery,
    isPending,
  } = usePostSylviaQuery<
    SylviaResponseTypes,
    AxiosError,
    Partial<SylviaFormValues>
  >(queryClient)

  const handleSubmit = async (values: SylviaFormValues) => {
    const newValues = showOptional
      ? Object.fromEntries(Object.entries(values).filter(([_, v]) => v !== ""))
      : { query: values.query }

    await postSylviaQuery(newValues)
  }

  const handleShowOptional = () => {
    setShowOptional((oldShowOptional) => !oldShowOptional)
  }

  return (
    <MainLayout isLoading={[]}>
      <div className="container xl:max-w-[976px] px-4 pt-12 pb-[132px]">
        <h1 className="text-3xl lg:text-5xl text-center mb-4">Sylvia</h1>

        <Formik
          initialValues={{
            query: "",
            corpus_name: "",
            model_name: "",
            top_k: "",
            vector_distance_threshold: "",
            vector_similarity_threshold: "",
            metadata_filter: "",
            system_instruction: "",
          }}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          <SylviaFormComponent
            showOptional={showOptional}
            handleShowOptional={handleShowOptional}
          />
        </Formik>

        <SylviaContent data={data} isPending={isPending} />
      </div>
    </MainLayout>
  )
}

export default Sylvia
