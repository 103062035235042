import { useEffect } from "react"
import { useQueryClient } from "@tanstack/react-query"

import MainLayout from "./_layouts/Main"
import BLOB from "../images/blob.png"
import DEER from "../images/deer.gif"

const Error404 = () => {
  const queryClient = useQueryClient()

  useEffect(() => {
    queryClient.setQueryData(["is-error-404"], true)

    return () => {
      queryClient.setQueryData(["is-error-404"], false)
    }
  }, [queryClient])

  return (
    <MainLayout contentBg="haze">
      <div className="container max-w-1120">
        <div className="w-full grid grid-cols-12 grid-rows-[repeat(2,_minmax(min-content,_max-content))] md:grid-rows-1 gap-6 md:gap-5 py-8 md:py-16">
          <div className="md:flex md:flex-col md:justify-center col-start-1 col-end-13 md:col-end-6 text-center md:text-left">
            <p className="text-dusk text-sm font-semibold leading-130 tracking-0.14 mb-4">
              Error: 404 page not found
            </p>

            <h1 className="text-charcoal-900 text-4xl leading-[124%] tracking-[0.72px] mb-2">
              Oh deer!
            </h1>

            <p className="text-charcoal-900 text-xl font-bold leading-6 tracking-0.4">
              Just like trees, sometimes web pages need a little time to grow.
            </p>
          </div>

          <div className="flex justify-center md:justify-end col-start-1 md:col-start-7 col-end-13 row-start-1 md:row-start-auto">
            <div className="relative w-242 md:w-auto max-w-full">
              <img src={BLOB} alt="blob" />

              <img
                src={DEER}
                alt="deer"
                className="w-[77.589%] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
              />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default Error404
