import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router"
import { useQueryClient } from "@tanstack/react-query"

import MainLayout from "./_layouts/Main"
import { Spinner } from "../components/Spinner"
import BecomeAMemberWidget from "../sections/BecomeAMember/BecomeAMemberWidget"
import BecomeAMemberTestimonials from "../sections/BecomeAMember/BecomeAMemberTestimonials"
import BecomeAMemberHighlights from "../sections/BecomeAMember/BecomeAMemberHighlights"
import BecomeAMemberSamples from "../sections/BecomeAMember/BecomeAMemberSamples"
import { useAccount, useAccountId } from "../hooks"

const BecomeAMember = () => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const { scrollToTop } = location.state || {}

  const { data: account, isLoading: accountIsLoading } = useAccount(
    queryClient,
    accountId
  )

  useEffect(() => {
    if (scrollToTop) {
      window.scrollTo(0, 0)
    }
  }, [scrollToTop])

  if (account?.has_purchased_nca_report || account?.is_member) {
    navigate("../", { replace: true })

    return (
      <div className="flex-auto flex items-center justify-center">
        <Spinner />
      </div>
    )
  }

  return (
    <MainLayout isLoading={[accountIsLoading]} contentBg="white">
      <div className="container px-4 pt-[92px] pb-[132px]">
        <h1 className="text-4xl md:text-[50px] text-charcoal-500 text-center font-medium leading-[124%] md:leading-[110%] tracking-0.4 md:tracking-[1px]">
          Become a member
        </h1>

        <h2 className="text-autumn text-xl md:text-3xl text-center font-medium leading-[140%] md:leading-[124%] tracking-0.4 md:tracking-[0.6px] my-7">
          Access the tools and insights you need to achieve your goals
        </h2>

        <BecomeAMemberWidget />

        <BecomeAMemberTestimonials />

        <BecomeAMemberHighlights />

        <BecomeAMemberSamples />
      </div>
    </MainLayout>
  )
}

export default BecomeAMember
